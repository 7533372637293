@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap&text=0123456789");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
    @apply max-w-[500px] lg:mx-auto flex disabled:from-slate-300 disabled:to-slate-300 bg-gradient-to-tr to-[#ff9289] from-[#ff0f47] hover:from-[#ff0f47]/80 hover:to-[#ff9289]/80 text-center text-lg items-center justify-center font-medium rounded-xl w-full p-2.5 text-white;
    /* @apply block mt-5 disabled:from-slate-300 disabled:to-slate-300 bg-gradient-to-r to-[#FF8C7F] from-[#F7CCBB] text-center text-lg font-medium rounded-lg w-full p-2.5 text-white; */
}

.bg-gradient-primary {
    @apply bg-gradient-to-t to-[#ff9d8f] from-[#ff0f47] from-10%;
}

.bg-gradient-white {
    @apply bg-gradient-to-t to-[#f9f9f9] from-[#ffffff] from-15%;
}

.badge {
    @apply rounded-full px-3 h-[1.95rem] flex flex-shrink-0 items-center justify-center font-medium border-[1.3px] text-primary border-primary bg-transparent;
}

.badge-active {
    @apply rounded-full px-3 h-[1.95rem] flex flex-shrink-0 items-center justify-center font-medium border-[1.3px] text-white border-primary bg-primary;
}

#guests-filters::-webkit-scrollbar {
    display: none;
}

input.picker[type="date"] {
    position: relative;
}

input.picker[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background: transparent;
}

button:focus {
    outline: none;
}
